<template>
  <!--begin::Pricing-->
  <div>
    <div>
      <Buttons
        :btnCancel="false"
        :textSave="$t('GENERAL.NEW')"
        v-on:save="newMarket"
      />
    </div>
    <v-card>
      <v-card-text>
        <v-row gutters="2" justify="end">
          <v-col cols="12" md="12">
            <v-card-title>
              <v-spacer />
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                :label="$t('GENERAL.SEARCH')"
                single-line
                hide-details
              />
            </v-card-title>
            <v-data-table :headers="headers" :items="markets" :search="search">
              <template v-slot:[`item.name`]="{ item }">
                <router-link
                  :to="{
                    name: 'edit-markets',
                    params: { id: item.marketId },
                  }"
                >
                  {{ item.name }}
                </router-link>
              </template>
              <template v-slot:[`item.countriesName`]="{ item }">
                {{ item.auxName }}
                <v-tooltip
                  v-model="show"
                  bottom
                  v-if="item.aux"
                  max-width="600px"
                  allow-overflow
                  content-class="text-word-break"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <font-awesome-icon
                        icon="comment-dots"
                        class="font-size-1-3"
                      />
                    </v-btn>
                  </template>
                  <span>{{ item.countriesName }}</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import { GET_ALL_MARKETS } from "@/core/services/store/market/market.module";
import Buttons from "@/view/content/components/Buttons";

export default {
  components: {
    Buttons,
  },
  data() {
    return {
      search: "",
      itemsMarkets: [],
      itemsPerPage: 10,
      headers: [
        {
          text: this.$t("MARKETS.NAME"),
          align: "start",
          sortable: true,
          value: "name",
        },
        {
          text: this.$t("MARKETS.TITLE7"),
          value: "countriesName",
          sortable: false,
        },
      ],
    };
  },
  methods: {
    /**
     * Create new Market
     * @method newMarket
     * @returns 'newMarket' object whit data
     */
    newMarket() {
      this.$router.push({ name: "new-markets" });
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t("MARKETS.TITLE") }]);
    this.$store.dispatch(GET_ALL_MARKETS);
  },
  computed: {
    ...mapGetters(["markets"]),
  },
};
</script>

<style scoped>
.text-word-break {
  word-break: break-all;
}

.font-size-1-3 {
  font-size: 1.3rem;
}
</style>
